import React from 'react';

import Price from '../../component/price/pricelist';

import './promosi.css'

const Promotion = () => {
    return (
        <div>
        <Price />
        </div>
    );
};

export default Promotion;