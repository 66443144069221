import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import './header.css'

const Header = () => {


    return (
        <div className="header_container">
            <div className="header_overlay">
                <div className="header_content">
                    <img height='150px' src={require('../../assets/pmj.png')} alt='PMJ logo' />
                    <h1>Welcome to Pusat Memandu Jesselton</h1>
                    <p>Pusat Memandu Jesselton adalah untuk menyediakan pelajar-pelajar kami dengan program latihan pemandu berkualiti tinggi yang direka bentuk secara khusus untuk menangani secara positif aspek penting pengetahuan, kemahiran dan sikap pemandu, dan memasukkan prinsip-prinsip pengajaran dewasa yang terkini untuk mengurangkan secara aktif kenderaan Negara statistik kemalangan.</p>
                    <Link to="/daftar">
                        <Button renderAs="button">
                            <span>Daftar Sekarang</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Header;