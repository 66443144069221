import React from 'react';

import './footer.css';

const Footer = () => {
    return (
        <div className="footer_container">
            <p>Pusat Memandu Jesselton Sdn. Bhd. Since 2002</p>
        </div>
    );
};

export default Footer;