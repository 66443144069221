import React from 'react';
import { Table } from 'react-bootstrap';
import { CheckCircle } from '@material-ui/icons';
import StarsIcon from '@material-ui/icons/StarBorder';

import './pelajaran.css';

const Pelajaran = () => {
	return (
		<div>
			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Pihak Pusat Memandu Jesselton menyediakan perkhidmatan pengurusan
					pengambilan Lesen Memandu mengikut kategori seperti:
				</h4>
				<Table striped bordered hover>
					<tbody>
						<tr>
							<td>B</td>
							<td>Motosikal melebihi 500 SP</td>
						</tr>
						<tr>
							<td>B2</td>
							<td>Motosikal tidak melebihi 250 SP</td>
						</tr>
						<tr>
							<td>D</td>
							<td>Motokar (Manual) BTM tidak melebihi 3500 KG</td>
						</tr>
						<tr>
							<td>DA</td>
							<td>
								Motokar (Automatic) Tanpa Pedal Klac BTM tidak melebihi 3500 KG
							</td>
						</tr>
						<tr>
							<td>E</td>
							<td>Motokar / Lori Berat BTM melebihi 7500 KG</td>
						</tr>
						<tr>
							<td>GDL</td>
							<td>Berat Kejur</td>
						</tr>
						<tr>
							<td>PSV</td>
							<td>Bas Mini / Van</td>
						</tr>
						<tr>
							<td>PSV</td>
							<td>Teksi / Teksi Mewah / Kereta Sewa / E-Hailing</td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Jenis Lesen Memandu yang layak dipohon
				</h4>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Status Warganegara</th>
							<th>Lesen Memandu PDL / CDL Kelas B/B2/D/DA/E</th>
							<th>Lesen Memandu Vokasional GDL / PSV</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Orang Awam Malaysia (MY KAD)</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
						</tr>
						<tr>
							<td>Anggota Polis (MY KAD)</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
						</tr>
						<tr>
							<td>Anggota Tentera (MY TENTERA)</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
						</tr>
						<tr>
							<td>Penduduk Tetap Malaysia (MY PR)</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>Penduduk Pemastautin Sementara (MY KAS)</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>Passport Bukan Warganegara Malaysia</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Kelayakan umur memohon Lesen Memandu
				</h4>
				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Umur</th>
								{/* <th>KPP01</th>
								<th>Ujian Komputer / Bertulis</th> */}
								<th>Motosikal B/B2</th>
								<th>Motokar D/DA</th>
								<th>Motokar / Lori E</th>
								<th>Lesen Memandu vokasional GDL/PSV </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>16 Tahun ke atas</th>
								{/* <td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td> */}
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>17 Tahun ke atas</th>
								{/* <td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td> */}
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>21 Tahun ke atas</th>
								{/* <td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td> */}
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>21 Tahun ke atas & lesen CDL Kelas D lebih setahun</th>
								{/* <td></td>
								<td></td> */}
								<td></td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Jenis kenderaan yang boleh dipandu mengikut Kelas Lesen Memandu PDL /
					CDL
				</h4>
				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Kelas Lesen Memandu</th>
								<th>Motosikal 500sp</th>
								<th>Motosikal 250sp</th>
								<th>Motokar Manual</th>
								<th>Motokar Automatic</th>
								<th>Motokar / Lori</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>B</th>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>B2</th>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<th>D</th>
								<td></td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
							</tr>
							<tr>
								<th>DA</th>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
							</tr>
							<tr>
								<th>E</th>
								<td></td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Syarat Kelayakan Ujian Bertulis BAHAGIAN 1 Telah Ujian Komputer 3 Kali
					Atau Lebih
				</h4>
				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Status Warganegara</th>
								<th>Ujian Bertulis BAHAGIAN 1</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Orang Awam Malaysia (MyKad)</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>Anggota Polis (MyKad)</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>Anggota Tentera (MyTentera)</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>Penduduk Tetap Malaysia (MyPR)</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>Penduduk Permastautin Sementara (MyKas)</td>
								<td></td>
							</tr>
							<tr>
								<td>Passport Bukan Warganergara Malaysia</td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">Pertukaran Kelas Lesen Memandu LDL</h4>
				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Perkara</th>
								<th>Kelulusan JPJ</th>
								<th>Tukar Kelas Lesen L</th>
								<th>KPP02</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>B2 → B</th>

								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>B → B2</th>

								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>DA → D</th>

								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>D → DA</th>

								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Pemegang Lesen Memandu LDL/ PDL / CDL – Tambah Kelas
				</h4>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Perkara</th>
							<th>KPP01</th>
							<th>Ujian Komputer / Bertulis</th>
							<th>LESEN L</th>
							<th>KPP02</th>
							<th>LESEN L</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>B/B2 → D/DA</td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>D/DA → B/B2</td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>B2 → B</td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>DA → D</td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
						</tr>
						<tr>
							<td>D → E</td>
							<td></td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Lesen Memandu LDL Tamat Tempoh 2 Tahun
				</h4>
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ width: '100%' }}
				>
					<div>
						<h6>
							<CheckCircle /> Pertama Kali Latihan
						</h6>
						<br />
						<h6>
							<StarsIcon /> Jika Pernah Latihan Tidak Perlu Mengulang
						</h6>
					</div>
				</div>

				<hr />
				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Kelas Lesen L</th>
								<th>KPP01</th>
								<th>Ujian Komputer / Bertulis</th>
								<th>Lesen L</th>
								<th>KPP02</th>
								<th>Lesen L</th>
								<th>KPP03</th>
								<th>Pra Ujian Amali</th>
								<th>Ujian Amali</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>B</th>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td></td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>B2</th>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td></td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>D</th>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td></td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>DA</th>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td></td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<th>E</th>
								<td></td>
								<td></td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
									<StarsIcon />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Lesen Memandu CDL – Tambah Kelas E Dan Lesen Memandu Vokasional
				</h4>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Perkara</th>
							<th>Motokar / Lori - E</th>
							<th>GDL</th>
							<th>PSV Bas Mini /Van</th>
							<th>PSV Teksi / Teksi Mewah / Kereta Sewa / E-Hailing</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>B → GDL</td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>B2 → GDL</td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td>D → E/GDL/PSV</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
							<td>
								<CheckCircle />
							</td>
						</tr>
						<tr>
							<td>DA → PSV</td>
							<td></td>
							<td></td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
						</tr>
						<tr>
							<td>E → GDL</td>
							<td></td>
							<td>
								<CheckCircle />
							</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">Tempoh Sah Setiap Bahagian</h4>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Perkara</th>
							<th>Tempoh</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>KPP01 Sijil - JPJL2A</td>
							<td>1 Tahun</td>
						</tr>
						<tr>
							<td>Lulus ujian Komputer / Bertulis Bahagian 1</td>
							<td>1 Tahun</td>
						</tr>
						<tr>
							<td>Lesen L</td>
							<td>2 Tahun</td>
						</tr>
						<tr>
							<td>KPP02 Sijil - JPJL2B</td>
							<td>1 Tahun</td>
						</tr>
						<tr>
							<td>Lulus Ujian Amali Bahagian 2</td>
							<td>1 Tahun</td>
						</tr>
						<tr>
							<td>Lulus Ujian Amali Bahagian 3</td>
							<td>1 Tahun</td>
						</tr>
						<tr>
							<td>Lesen P</td>
							<td>2 Tahun</td>
						</tr>
						<tr>
							<td>Kursus GDL / PSV Sijil - JPJL2C</td>
							<td>1 Tahun</td>
						</tr>
					</tbody>
				</Table>
			</div>

			<div className="pelajaran_container">
				<h4 className="pelajaran_title">
					Lesen Memandu Tamat Tempoh Dan Permohonan Rayuan
				</h4>

				<div className="table-responsive">
					<Table striped bordered hover className="table">
						<thead>
							<tr>
								<th>Lesen Memandu</th>
								<th>Tamat Tempoh Lesen</th>
								<th>E-Rayuan & Kelulusan JPJ</th>
								<th>Kelas Teori</th>
								<th>Lesen L</th>
								<th>Ujian Amali Bahagian 2/3</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>PDL</td>
								<td>1 Tahun</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>CDL</td>
								<td>3 Tahun</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
							</tr>
							<tr>
								<td>Vokasional</td>
								<td>3 Tahun</td>
								<td>
									<CheckCircle />
								</td>
								<td>
									<CheckCircle />
								</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

export default Pelajaran;
