import React from 'react';

import './pendaftaran.css';

const Pendaftaran = () => {
    return (
        <div className='pendaftaran_container'>
            <div classname='pendaftaran_img'>
            <h2>Motokar  – D / DA</h2>
            <img className="p_img" src={require('../../assets/Motokar.jpg')} alt='Motokar' />
            </div>
            <div classname='pendaftaran_img'>
            <h2>Motosikal  – B / B2</h2>
            <img className="p_img" src={require('../../assets/Motosikal.jpg')} alt='Motosikal' />
            </div>
        </div>
    );
};

export default Pendaftaran;