import React, { Component, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import './form.css';

class Formlist extends Component {
	state = {
		name: '',
		email: '',
		address: '',
		mobile: '',
		identity: '',
		subject: '',
		date: new Date().toLocaleString(),
	};

	handleChange = ({ target }) => {
		const { name, value } = target;
		this.setState({
			[name]: value,
		});
	};

	submit = (event) => {
		event.preventDefault();

		const payload = {
			// fullname: this.state.fullname,
			// mobile: this.state.mobile,
			// tempreture: this.state.tempreture,
			// date: this.state.date
			name: this.state.name,
			email: this.state.email,
			address: this.state.address,
			mobile: this.state.mobile,
			identity: this.state.identity,
			subject: this.state.subject,
			date: this.state.date,
		};

		axios({
			url: 'https://ascohosting.space:5001/api/save',
			method: 'POST',
			data: payload,
		})
			.then(() => {
				console.log('Data Has been send to the server');
				Swal.fire({
					icon: 'success',
					title:
						'Please check your email *inbox or spam* for your application information',
					showConfirmButton: true,
				});
				this.resetUserInputs();
			})
			.catch(() => {
				console.log('Failed to send to server');
				Swal.fire({
					icon: 'error',
					title: 'Oops! you might have already registered',
					text: 'Please check your email *inbox or spam* for your application information or call our office for further clarification',
				});
			});
	};

	resetUserInputs = () => {
		this.setState({
			name: '',
			email: '',
			address: '',
			mobile: '',
			identity: '',
			subject: '',
			date: new Date().toLocaleString(),
			posts: [],
		});
	};

	render() {
		return (
			<div className="form_container">
				<form onSubmit={this.submit}>
					<Form.Group controlId="formBasicName">
						<Form.Label>Nama Penuh</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Name"
							name="name"
							value={this.state.name}
							onChange={this.handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicMobile">
						<Form.Label>Nombor Telefon</Form.Label>
						<Form.Control
							type="number"
							placeholder="Enter mobile number"
							name="mobile"
							value={this.state.mobile}
							onChange={this.handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicEmail">
						<Form.Label>E-MEL * email yang aktif</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter a valid email address"
							name="email"
							value={this.state.email}
							onChange={this.handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicAddress">
						<Form.Label>Alamat Rumah</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter home address"
							name="address"
							value={this.state.address}
							onChange={this.handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicIdentity">
						<Form.Label>Nombor Kad Pengenalan / Passport</Form.Label>
						<Form.Control
							type="number"
							placeholder="Enter identity card / passport number"
							name="identity"
							value={this.state.identity}
							onChange={this.handleChange}
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicSelection">
						<Form.Label>Kelas Subjek</Form.Label>
						<Form.Control
							className="form_control"
							as="select"
							name="subject"
							value={this.state.subject}
							onChange={this.handleChange}
							required
						>
							<option></option>
							<option>Motokar D (MANUAL)</option>
							<option>Motokar DA (AUTO)</option>
							<option>Motosikal B (⇧ 500SP)</option>
							<option>Motosikal B2 (⇩ 250SP)</option>
							<option>Lori E</option>
							<option>GDL</option>
							<option>PSV BAS MINI / VAN</option>
							<option>PSV TAXI / E-HAILING</option>
						</Form.Control>
					</Form.Group>

					<Form.Group controlId="form Check">
						<p>
							Please ensure the all the fields are correct and check your email
							after you have submited the form ||| Sila pastikan isikan form
							dengan betul pastikan tiada kesalahan. Selepas daftar sila buka
							email anda untuk maklumat lebih lanjut.
							<Form.Check aria-label="option 1" required />
						</p>
					</Form.Group>

					<Button variant="primary" block type="submit">
						Submit
					</Button>
				</form>
			</div>
		);
	}
}

export default Formlist;
