import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FacebookIcon from '@material-ui/icons/Facebook'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InstagramIcon from '@material-ui/icons/Instagram'

import './navbar.css'

const NavigationBar = () => {
  return (
    <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark'>
      <Navbar.Brand>
        <Link className='dropdown2' to='/'>
          Pusat Memandu Jesselton
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='mr-auto'>
          <Nav.Link>
            <Link className='dropdown3' to='/promotion'>
              Promosi
            </Link>
          </Nav.Link>
          <NavDropdown title='Infomasi Kelas' id='collasible-nav-dropdown'>
            <div className='drop_container'>
              <Link className='dropdown' to='/peringatan'>
                Peringatan Pelajar
              </Link>
            </div>
            <NavDropdown.Divider />
            <div className='drop_container'>
              <Link className='dropdown' to='/pelajaran'>
                Pelajaran
              </Link>
            </div>
            <NavDropdown.Divider />
            <div className='drop_container'>
              <Link className='dropdown' to='/jadual'>
                Jadual
              </Link>
            </div>
            <NavDropdown.Divider />
            <div className='drop_container'>
              <Link className='dropdown' to='/pendaftaran'>
                Process Pendaftaran
              </Link>
            </div>
          </NavDropdown>
        </Nav>
        <Nav>
          <Nav.Link>
            <Link className='dropdown3' to='/hubungi'>
              Alamat / Peta Lokasi
            </Link>
          </Nav.Link>

          <Nav.Link href='tel:088499337'>Hubungi Kita</Nav.Link>
          <div className='nav_social'>
            <Nav.Link href='https://www.facebook.com/PMJHQ'>
              <FacebookIcon style={{ fontSize: 30 }} />
            </Nav.Link>
            <Nav.Link href='https://wa.me/60178789337?text=I%27m%20inquiring%20infomation%20Details'>
              <WhatsAppIcon className='nav_social2' style={{ fontSize: 30 }} />
            </Nav.Link>
            <Nav.Link href='https://www.instagram.com/pusatmemandujesselton/'>
              <InstagramIcon className='nav_social2' style={{ fontSize: 30 }} />
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavigationBar
