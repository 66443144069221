import React from 'react';

import Header from '../../component/header/header';


import './home.css';

const Home = () => {
    return (
        <div>
            <Header />
        </div>
    );
};

export default Home;