import React from 'react'

import './peringatan.css'

const Peringatan = () => {
  return (
    <div className='peringatan_container'>
      <div className='perigatan_content'>
        <h4 peringatan_title>Peringatan untuk semua pelajar</h4>
        <p>Pastikan anda membawa dokumen berikut semasa Latihan / Ujian</p>
        <ul className='peringatan_ul'>
          <h4>
            <li className='peringatan_title'>
              Kad Pengenalan asal / Passport asal yang sah
            </li>
            <li className='peringatan_title'>
              L lesen Memandu LDL asal yang sah (Jika Ada)
            </li>
            <li className='peringatan_title'>
              Lesen Memandu CDL asal yang sah (Jika Ada)
            </li>
          </h4>
        </ul>
      </div>
      <h4 className='pt-5'>Etika Pakaian Sopan Semasa Menghadiri Kursus</h4>
      <img
        className='etika'
        src={require('../../assets/etika.png')}
        alt='dresscode'
      ></img>
    </div>
  )
}

export default Peringatan
