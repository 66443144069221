import React from 'react';

import Googlemap from '../../component/googlemap/googlemap';

import './hubungi.css';

const Hubungi = () => {
	return (
		<div className="hubungi_container">
			<Googlemap />
			<div className="hubungi_address">
				<p>
					Km 16 , Kampung Labau Menggatal , Jalan Tuaran , 88450 Kota kinabalu ,
					Sabah Malaysia
				</p>
			</div>
			<div className="d-flex justify-content-center align-items-center text-center">
				<div>
					<h5>PERTANYAAN</h5>
					<h6>Office</h6>
					<p>088-499336 088-499337</p>
					<h6>Whatsapp / Mobile</h6>
					<p>017-8789337 010-8219337</p>
					<hr />
					<h5>UJIAN KOMPUTER</h5>
					<p> 012-8299337</p>
					<hr />
					<h5>K2 MOTOR/GDL/PSV </h5> <p>012-8109337</p>
					<hr />
					<h5>JPJ TEST</h5>
					<p>016-8729337</p>
				</div>
			</div>
		</div>
	);
};

export default Hubungi;
