import React from 'react';

class Googlemap extends React.Component {
	componentDidMount() {
		this.renderMap();
	}

	renderMap = () => {
		loadScript(
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyCfOEalzIATSUh4mX3IdxoroDpYePHUqn4&callback=initMap'
		);
		window.initMap = this.initMap;
	};

	initMap = () => {
		var myLatLng = { lat: 6.048893, lng: 116.1754 };
		// eslint-disable-next-line
		var map = new window.google.maps.Map(document.getElementById('map'), {
			center: myLatLng,
			zoom: 17,
		});
		// eslint-disable-next-line
		var marker = new window.google.maps.Marker({
			position: myLatLng,
			map: map,
			title: 'Hello World!',
		});
	};

	render() {
		return (
			<main>
				<div id="map"></div>
			</main>
		);
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName('script')[0];
	var script = window.document.createElement('script');
	script.src = url;
	script.async = true;
	script.defer = true;
	index.parentNode.insertBefore(script, index);
}

export default Googlemap;
