import React from 'react';
import { Table } from 'react-bootstrap';
import { CheckCircle } from '@material-ui/icons';

import './jadual.css';

const Jadual = () => {
	return (
		<div>
			<div className="jadual_container">
				<h2 className="jadual_title">JADUAL LATIHAN / UJIAN</h2>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Perkara</th>
							<th>Kategori</th>
							<th>Jadual</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>MOTOKAR</td>
							<td>KPP01</td>
							<td>Setiap Sabtu atau Ahad kecuali cuti umum</td>
						</tr>

						<tr>
							<td>MOTOSIKAL</td>
							<td>KPP01</td>
							<td>1 bulan 1 kali</td>
						</tr>

						<tr>
							<td>MOTOSIKAL / MOTOKAR</td>
							<td>UJIAN BERTULIS</td>
							<td>Jadual dari JPJ</td>
						</tr>

						<tr>
							<td>MOTOKAR / LORI</td>
							<td>KPP02</td>
							<td>Setiap hari kecuali ujian amali / cuti umum</td>
						</tr>

						<tr>
							<td>MOTOSIKAL</td>
							<td>KPP02</td>
							<td>Latihan dibuka setelah cukup 5 calon</td>
						</tr>

						<tr>
							<td>MOTOSIKAL / MOTOKAR / LORI</td>
							<td>KPP03</td>
							<td>Latihan ikut jadual tutor</td>
						</tr>

						<tr>
							<td>MOTOKAR / LORI</td>
							<td>PRA UJIAN AMALI</td>
							<td>Setiap hari kecuali ujian amali / cuti umum</td>
						</tr>

						<tr>
							<td>MOTOSIKAL</td>
							<td>PRA UJIAN AMALI</td>
							<td>1 bulan 3 kali</td>
						</tr>

						<tr>
							<td>MOTOSIKAL / MOTOKAR / LORI</td>
							<td>UJIAN AMALI</td>
							<td>Jadual dari JPJ</td>
						</tr>

						<tr>
							<td>GDL</td>
							<td>KELAS TEORI</td>
							<td>Jadual dari JPJ</td>
						</tr>

						<tr>
							<td>PSV BAS MINI / VAN</td>
							<td>KELAS TEORI</td>
							<td>Jadual dari JPJ</td>
						</tr>

						<tr>
							<td>PSV TEKSI / TEKSI MEWAH / KERETA SEWA / E-HAILING</td>
							<td>KELAS TEORI</td>
							<td>1 bulan 1 kali</td>
						</tr>
						{/* 
						<tr>
							<td>GDL / PSV</td>
							<td>UJIAN TEORI</td>
							<td>Jadual dari JPJ</td>
						</tr> */}
					</tbody>
				</Table>
			</div>

			<div className="jadual_container2">
				<h2 className="jadual_title">LESEN MEMANDU VOKASIONAL</h2>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Perkara</th>
							<th>Kelas Teori</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>GDL - Berat Kejur</td>
							<td>7 Jam</td>
						</tr>
						<tr>
							<td>PSV - Bas Mini / Van</td>
							<td>7 Jam</td>
						</tr>
						<tr>
							<td>PSV - Teksi / Teksi Mewah / Kereta Sewa / E-Hailing</td>
							<td>6 Jam</td>
						</tr>
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default Jadual;
