import React from 'react';
import Display from '../../component/display2/display';

import './backoffice.css';

const Backoffice = () => {
	return (
		<div style={{ backgroundColor: 'dark' }}>
			<Display />
		</div>
	);
};

export default Backoffice;
