import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/home/home';
import Promotion from './pages/promosi/promosi';
import Jadual from './pages/jadual/jadual';
import Pelajaran from './pages/pelajaran/pelajaran';
import Hubungi from './pages/hubungi/hubungi';
import NavigationBar from './component/navbar/navbar';
import Footer from './component/footer/footer';
import Daftar from './pages/daftar/daftar';
import Detail from './component/detail/detail';
import Backoffice from './pages/backoffice/backoffice';
import Peringatan from './pages/peringatan/peringatan';
import Pendaftaran from './pages/pendaftaran/pendaftaran';
import NoMatch from './pages/nomatch/nomatch';

import './App.css';

function App() {
	return (
		<div>
			<Router>
				<NavigationBar />
				<Detail />
				<Switch>
					<Route exact path="/" component={Home}></Route>
					<Route path="/promotion" component={Promotion}></Route>
					<Route path="/jadual" component={Jadual}></Route>
					<Route path="/pelajaran" component={Pelajaran}></Route>
					<Route path="/hubungi" component={Hubungi}></Route>
					<Route path="/daftar" component={Daftar}></Route>
					<Route path="/paneloffice" component={Backoffice}></Route>
					<Route path="/peringatan" component={Peringatan}></Route>
					<Route path="/pendaftaran" component={Pendaftaran}></Route>
					<Route path="*" component={NoMatch}></Route>
				</Switch>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
