import React from 'react';

const NoMatch = () => {
    return (
        <div>
            <h2> This paget was not available click back to return</h2>
        </div>
    );
};

export default NoMatch;