import React, { PureComponent } from 'react';
import Formlist from '../../component/form/form';

import './daftar.css'

class Daftar extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                <Formlist />
            </div>
        )
    }
}

export default Daftar