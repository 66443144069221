import React, { Component } from 'react';
import axios from 'axios';

import Posts from '../post/post';
import Pagination from '../pagination/pagination';
import { Table } from 'react-bootstrap';

import './display.css';

export class Display extends Component {
	state = {
		posts: [],
		loading: false,
		currentPage: 1,
		postsPerPage: 40,
	};

	componentDidMount() {
		const getPosts = async () => {
			this.setState({ loading: true });
			const results = await axios.get('https://ascohosting.space:5001/api');
			this.setState({ posts: results.data });
			this.setState({ loading: false });
		};

		getPosts();
	}

	render() {
		const { currentPage, postsPerPage, posts, loading } = this.state;

		const indexOfLastPost = currentPage * postsPerPage;
		const indexOfFirstPost = indexOfLastPost - postsPerPage;
		const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

		const paginate = (pageNum) => this.setState({ currentPage: pageNum });

		const nextPage = () => this.setState({ currentPage: currentPage + 1 });

		const prevPage = () => this.setState({ currentPage: currentPage - 1 });

		return (
			<div
				className="display_container justify-content-center"
				style={{ height: 'auto' }}
			>
				<h1 className="text-light text-center">Online Daftar</h1>
				<Table striped bordered hover responsive variant="dark">
					<thead>
						<tr>
							<th>Nama Calon</th>
							<th>Email</th>
							<th>Alamat Rumah</th>
							<th>Nombor Telephone</th>
							<th>Kad Pengenalan / Passport</th>
							<th>Kelas Subjek</th>
							<th>Tarikh Daftar</th>
						</tr>
					</thead>
					<Posts posts={currentPosts} loading={loading} />
				</Table>

				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={posts.length}
					paginate={paginate}
					nextPage={nextPage}
					prevPage={prevPage}
				/>
			</div>
		);
	}
}

export default Display;
