import React from 'react';
import { Table } from 'react-bootstrap';

import './table.css';

const Tablelist = (props) => {
	return (
		<div className="table_container">
			<p className="text-center">Tidak Termasuk</p>
			<Table striped bordered hover size="md">
				<thead></thead>
				<tbody>
					<tr>
						<td colSpan="3">{props.d1}</td>
						<td>{props.d1t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d9}</td>
						<td>{props.d9t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d10}</td>
						<td>{props.d10t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d2}</td>
						<td>{props.d2t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d3}</td>
						<td>{props.d3t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d4}</td>
						<td>{props.d4t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d5}</td>
						<td>{props.d5t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d6}</td>
						<td>{props.d6t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d7}</td>
						<td>{props.d7t2}</td>
					</tr>
					<tr>
						<td colSpan="3">{props.d8}</td>
						<td>{props.d8t2}</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

export default Tablelist;
