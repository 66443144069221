import React from 'react';
import { Table } from 'react-bootstrap';

import './table.css';

const Tablelist = (props) => {
	return (
		<div className="table_container">
			<p className="text-center">Harga tidak termasuk</p>
			<Table striped bordered hover size="md">
				<thead></thead>
				<tbody>
					<tr>
						{/* <td colSpan='3'>{props.d2}</td>
            <td>{props.d2t2}</td>
          </tr>
          <tr>
            <td colSpan='3'>{props.d3}</td>
            <td>{props.d3t2}</td>
          </tr>
          <tr> */}
						<td colSpan="3">{props.d4}</td>
						<td>{props.d4t2}</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

export default Tablelist;
