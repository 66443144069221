import React, { useRef } from 'react';

import Tablelist from '../table/table';
import Tablelist2 from '../table/table2';
import Tablelist3 from '../table/table3';
import Tablelist4 from '../table/table4';
import Cardlist from '../card/card';
import Cardlist2 from '../card/card2';
import Cardlist3 from '../card/card3';
import {
	Jumbotron,
	Container,
	DropdownButton,
	Dropdown,
	ButtonGroup,
} from 'react-bootstrap';

import './pricelist.css';
import logo8 from '../../assets/Lori.png';
import logo from '../../assets/kawasaki.jpg';
import logo2 from '../../assets/yellow.jpg';
import logo3 from '../../assets/vanlori.png';
import logo4 from '../../assets/bas.png';
import logo5 from '../../assets/taxi.png';
import logo6 from '../../assets/Manual.jpg';
import logo7 from '../../assets/Auto.png';

const Price = () => {
	const motorSection = useRef(null);
	const gotoMotorSection = () =>
		window.scrollTo({
			top: motorSection.current.offsetTop,
			behavior: 'smooth',
		});

	const loriSection = useRef(null);
	const gotoloriSection = () =>
		window.scrollTo({ top: loriSection.current.offsetTop, behavior: 'smooth' });

	const vokasionalSection = useRef(null);
	const gotovokasionalSection = () =>
		window.scrollTo({
			top: vokasionalSection.current.offsetTop,
			behavior: 'smooth',
		});

	const motorkarSection = useRef(null);
	const gotomotorkarSection = () =>
		window.scrollTo({
			top: motorkarSection.current.offsetTop,
			behavior: 'smooth',
		});

	return (
		<div>
			<div className="pricelist_header">
				<div className="pricelist_headeroverlay">
					<h4>
						Harga ini tertakluk kepada tempoh sah promosi iaitu dari 1.3.2024
						Hingga 31.12.2024. Selepas tarikh tersebut, semua harga akan berubah
						kepada harga biasa. Mana-mana bahagian yang belum diambil juga akan
						mengikut kadar biasa selepas tempoh promosi tamat.
					</h4>
					<p>
						Bayaran Secara Berperingkat
						<br />
						Harga Termasuk SST
					</p>
					<div className="nav_container">
						<DropdownButton
							as={ButtonGroup}
							title="Looking For"
							id="bg-nested-dropdown"
						>
							<Dropdown.Item eventKey="1" onClick={gotomotorkarSection}>
								Motokar
							</Dropdown.Item>
							<Dropdown.Item eventKey="1" onClick={gotoMotorSection}>
								Motosikal
							</Dropdown.Item>
							<Dropdown.Item eventKey="2" onClick={gotoloriSection}>
								Lori
							</Dropdown.Item>
							<Dropdown.Item eventKey="2" onClick={gotovokasionalSection}>
								Vokasional
							</Dropdown.Item>
						</DropdownButton>
					</div>
				</div>
			</div>

			<div className="price_cardlist_container" ref={motorkarSection}>
				<Cardlist
					url={logo6}
					price1="RM 1295"
					price2="RM 1390"
					title="MOTOKAR MANUAL KELAS - D"
					description="Bayaran secara berperingkat"
					info2="Harga termasuk SST"
					info="Pakej termasuk kit kursus buku dan ujian komputer"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					t1="KPP01 – RM199"
					t2="KPP02 – RM259"
					t3="KPP03 – RM499"
					t4="PRA UJIAN AMALI – RM129"
					t5="UJIAN AMALI – RM209"
				/>
				<Tablelist
					d1="Ulangan UJIAN KOMPUTER Atau Tidak Hadir UJIAN KOMPUTER"
					d1t2="RM35"
					d2="LESEN L"
					d2t2="RM33"
					d3="KPP03 - Tambah 1 jam"
					d3t2="RM49"
					d4="Ulangan Pra Ujian Amali	"
					d4t2="FREE"
					d5="Ulangan Ujian Amali / KAD UJIAN"
					d5t2="RM108 / RM10"
					d6="Tidak Hadir Ujian Amali / KAD UJIAN"
					d6t2="RM54 / RM10"
					d7="Lesen P (2 Tahun) – Mykad Warganegara Malaysia"
					d7t2="RM60"
					d8="Lesen P (2 Tahun) – Passport / Bukan Warganegara Malaysia"
					d8t2="RM120"
					d9="Ulangan Ujian Bertulis / Kad Ujian Bertulis"
					d9t2="RM 29 / RM 10"
					d10="Tidak Hadil Ujian Bertulis / Kad Ujian Bertulis"
					d10t2="RM 10"
				/>
			</div>
			<div className="price_cardlist_container2">
				<Cardlist
					url={logo7}
					price1="RM 1475"
					price2="RM 1590"
					title="MOTOKAR AUTO KELAS - DA"
					description="Bayaran secara berperingkat"
					info2="Harga termasuk SST"
					info="Pakej termasuk kit kursus buku dan ujian komputer"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					t1="KPP01 – RM199"
					t2="KPP02 – RM299"
					t3="KPP03 – RM559"
					t4="PRA UJIAN AMALI – RM159"
					t5="UJIAN AMALI – RM259"
					oku="OKU Fizikal Anggota Kaki Atau Tangan ( Warganegara Malaysia ) FREE"
				/>

				<Tablelist
					d1="Ulangan UJIAN KOMPUTER Atau Tidak Hadir UJIAN KOMPUTER"
					d1t2="RM35"
					d2="LESEN L"
					d2t2="RM33"
					d3="KPP03 - Tambah 1 jam"
					d3t2="RM55"
					d4="Ulangan Pra Ujian Amali	"
					d4t2="FREE"
					d5="Ulangan Ujian Amali / KAD UJIAN"
					d5t2="RM108 / RM10"
					d6="Tidak Hadir Ujian Amali / KAD UJIAN"
					d6t2="RM54 / RM10"
					d7="Lesen P (2 Tahun) – Mykad Warganegara Malaysia"
					d7t2="RM60"
					d8="Lesen P (2 Tahun) – Passport / Bukan Warganegara Malaysia"
					d8t2="RM120"
					d9="Ulangan Ujian Bertulis / Kad Ujian Bertulis"
					d9t2="RM 29 / RM 10"
					d10="Tidak Hadil Ujian Bertulis / Kad Ujian Bertulis"
					d10t2="RM 10"
				/>
			</div>

			<div className="price_cardlist_container" ref={motorSection}>
				<Cardlist
					url={logo}
					price1="RM859"
					title="MOTOSIKAL KELAS - B (MELEBIHI 500SP)"
					description="Bayaran secara berperingkat"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					info2="Harga termasuk SST"
					info="Pakej termasuk kit kursus buku dan ujian komputer"
					t1="KPP01 – RM199"
					t2="KPP02 – RM230"
					t3="KPP03 – RM150"
					t4="PRA UJIAN AMALI – RM110"
					t5="UJIAN AMALI – RM170"
				/>
				<Tablelist2
					d1="Ulangan UJIAN KOMPUTER Atau Tidak Hadir UJIAN KOMPUTER"
					d1t2="RM35"
					d2="Lesen L"
					d2t2="Harga Ditetapkan JPJ"
					d3="KPP03 - Tambah 1 jam"
					d3t2="RM25"
					d4="Ulangan Pra Ujian Amali	"
					d4t2="FREE"
					d5="Ulangan Ujian Amali / KAD UJIAN"
					d5t2="RM108 / RM10"
					d6="Lesen P"
					d6t2="Harga Ditetapkan JPJ"
					d7="Ulangan Ujian Bertulis / Kad Ujian Bertulis"
					d7t2="RM 29 / RM 10"
					d8="Tidak Hadil Ujian Bertulis / Kad Ujian Bertulis"
					d8t2="RM 10"
				/>
			</div>

			<div className="price_cardlist_container2">
				<Cardlist
					url={logo2}
					price1="RM 559"
					title="MOTOSIKAL KELAS - B2 
          (TIDAK MELEBIHI 250SP)"
					description="Bayaran secara berperingkat"
					info2=" Harga termasuk SST"
					info="Pakej termasuk kit kursus buku dan ujian komputer"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					t1="KPP01 – RM199"
					t2="KPP02 – RM140"
					t3="KPP03 – RM90"
					t4="PRA UJIAN AMALI – RM50"
					t5="UJIAN AMALI – RM80"
				/>
				<Tablelist2
					d1="Ulangan UJIAN KOMPUTER Atau Tidak Hadir UJIAN KOMPUTER"
					d1t2="RM35"
					d2="Lesen L"
					d2t2="Harga Ditetapkan JPJ"
					d3="KPP03 - Tambah 1 jam"
					d3t2="RM15"
					d4="Ulangan Pra Ujian Amali	"
					d4t2="FREE"
					d5="Ulangan Ujian Amali / KAD UJIAN"
					d5t2="RM54 / RM10"
					d6="Lesen P"
					d6t2="Harga Ditetapkan JPJ"
					d7="Ulangan Ujian Bertulis / Kad Ujian Bertulis"
					d7t2="RM 29 / RM 10"
					d8="Tidak Hadil Ujian Bertulis / Kad Ujian Bertulis"
					d8t2="RM 10"
				/>
			</div>

			<Jumbotron fluid>
				<Container>
					<h3>
						Peringkat-peringkat ambil lesen memandu: (LESEN MOTOKAR atau
						MOTOSIKAL)
					</h3>
					<ul>
						<li>Menghadiri Ceramah KPP01</li>
						<li>Latian, Buat tempahan awal dan menduduki UJIAN KOMPUTER</li>
						<li>
							Jika calon gagal atau tidak hadir pada hari tersebut, calon kena
							bayar ulangan
						</li>
						<li>Jika lulus ujian komputer, proses LESEL L di JPJ</li>
						<li>Calon akan rujuk dengan pengajar bagi atur masa untuk KPP02</li>
						<li>
							Calon atur tarikh dan masa dengan pengajar masing-masing untuk
							KPP03
						</li>
						<li>Pengajar akan atur masa dengan calon untuk PRA UJIAN AMALI</li>
						<li>
							Pengajar hantar dokumen calon ke pejabat PMJ untuk buat tempahan
							UJIAN AMALI
						</li>
						<li>
							Jika gagal, calon bayar ulangan dan menunggu giliran bagi tarikh
							ujian seterusnya
						</li>
						<li>Jika lulus, calon boleh proses LESEN P di JPJ</li>
					</ul>
				</Container>
			</Jumbotron>

			<div className="price_cardlist_container2" ref={loriSection}>
				<Cardlist3
					url={logo8}
					price1="RM 1399"
					title="LORI KELAS - E"
					description="Bayaran secara berperingkat"
					info=" Harga termasuk SST"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					t1="PENDAFTARAN – FREE"
					t2="KPP02 – RM399"
					t3="KPP03 – RM560"
					t4="PRA UJIAN AMALI – RM160"
					t5="UJIAN AMALI – RM280"
				/>
				<Tablelist3
					d1="LESEN L	"
					d1t2="RM33"
					d2="KPP03 - Tambah 1 jam"
					d2t2="RM70"
					d3="Ulangan PRA UJIAN AMALI"
					d3t2="FREE"
					d4="Ulangan UJIAN AMALI / KAD UJIAN"
					d4t2="RM108 / RM10"
					d5="LESEN CDL"
					d5t2="Harga ditetapkan JPJ"
				/>
			</div>

			<Jumbotron fluid>
				<Container>
					<h3>Peringkat – peringkat untuk ambil GDL / PSV </h3>
					<ul>
						<li>Ambil borang Medical GDL / PSV dari PMJ</li>
						<li>Medical checkup di mana-mana klinik / hospital</li>
						<ul>
							<li>Hantar borang Medical ke pejabat PMJ</li>
							<li>Resit asal pembayaran dari klinik / hospital</li>
							<li>Salinan IC dan lesen memandu CDL</li>
							<li>Bayar RM169</li>
						</ul>

						<li>Menghadiri ceramah yang diberikan tarikh</li>
						<li>Proses Lesen GDL/PSV di JPJ</li>
					</ul>
				</Container>
			</Jumbotron>

			<div className="price_cardlist_container2" ref={vokasionalSection}>
				<Cardlist2
					url={logo3}
					title="GDL"
					description="Harga termasuk SST"
					info="Pakej termasuk buku"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					t1="Kelas Teori"
					tt1="RM169"
				/>
				<Tablelist4
					// d2="Ulangan Ujian Teori / Kad Ujian Teori"
					// d2t2="RM 27 / RM 10"
					// d3="Tidak Hadir Ujian Teori / Kad Ujian Teori"
					// d3t2="RM 10"
					d4="Lesen Vokasional (1 Tahun)"
					d4t2="RM 20"
				/>
			</div>

			<div className="price_cardlist_container">
				<Cardlist2
					url={logo4}
					title="PSV – BAS MINI / VAN"
					description="Harga termasuk SST"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					info="Pakej termasuk buku"
					t1="Kelas Teori "
					tt1="RM169"
				/>
				<Tablelist4
					// d2="Ulangan Ujian Teori / Kad Ujian Teori"
					// d2t2="RM 27 / RM 10"
					// d3="Tidak Hadir Ujian Teori / Kad Ujian Teori"
					// d3t2="RM 10"
					d4="Lesen Vokasional (1 Tahun)"
					d4t2="RM 20"
				/>
			</div>

			<div className="price_cardlist_container2">
				<Cardlist2
					url={logo5}
					title="PSV – TEKSI / TEKSI MEWAH / KERETA SEWA / E-HAILING"
					description="Harga termasuk SST"
					info3="Tiada harga tersembunyi dan tiada syarat khas"
					info="Pakej termasuk buku"
					t1="Kelas Teori"
					tt1="RM169"
				/>
				<Tablelist4
					// d2="Ulangan Ujian Teori / Kad Ujian Teori"
					// d2t2="RM 27 / RM 10"
					// d3="Tidak Hadir Ujian Teori / Kad Ujian Teori"
					// d3t2="RM 10"
					d4="Lesen Vokasional (1 Tahun)"
					d4t2="RM 20"
				/>
			</div>
		</div>
	);
};

export default Price;
