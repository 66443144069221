import React, { Component } from 'react'

export class Posts extends Component {
    render() {
        const { posts, loading } = this.props;

        if (loading) {
            return <h2>Loading...</h2>
        }

        return (
                posts.map(post => (
                    
                        <tr key={post.id}>
                            <td>{post.name}</td>
                            <td>{post.email}</td>
                            <td>{post.address}</td>
                            <td>{post.mobile}</td>
                            <td>{post.identity}</td>
                            <td>{post.subject}</td>
                            <td>{post.date}</td>
                        </tr>
                   
                ))
            
        )
    }
}

export default Posts