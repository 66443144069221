import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
// import Mail from '@material-ui/icons/Mail';

import './detail.css';

const Detail = () => {
	return (
		<div className="detail_container">
			<p>
				<PhoneIcon fontSize="small" className="detail_icon" /> Pertanyaan: 088
				499337 / 010 821 9337 / 017 878 9337
			</p>
			{/* <p>
				<Mail fontSize="small" /> info@pmjdriving.com.my
			</p> */}
		</div>
	);
};

export default Detail;
